<template>
  <div id="blog-list">
    <Navigation/>
    <div class="blog-header-container">
      <div class="blog-header">
        <div class="title">Blog Post Category</div>
        <div class="blog-search">
          <input type="search" placeholder="Search" v-model="search" @change="getBlogs">
        </div>
      </div>
      <ul class="blog-categories animate__animated animate__fadeInUp">
        <li :class="0 == currentCategoryID ? 'selected':''">
          <a href="/blogs">전체보기</a>
        </li>
        <li v-for="category in categories" :key="category.id" :class="category.id == currentCategoryID ? 'selected':''">
          <a :href="'/category/' + category.url">{{category.name}}</a>
        </li>
      </ul>
    </div>
    <div class="blog-header-container-mobile">
      <div class="blog-search">
        <input type="search" placeholder="Search" v-model="search" @change="getBlogs">
      </div>
      <a href="#" class="btn-blog-open-filters" @click="showFilters = true">
        <img src="/assets/icons/search-filter-gray.png" alt="">
      </a>
    </div>
    <div v-if="showFilters" class="blog-categories-mobile">
      <div>
        <div class="title">Blog Post Category</div>
        <ul class="blog-categories-mobile animate__animated animate__fadeInUp">
          <li :class="0 == currentCategoryID ? 'selected':''">
            <a href="/blogs">전체보기</a>
          </li>
          <li v-for="category in categories" :key="category.id" :class="category.id == currentCategoryID ? 'selected':''">
            <a :href="'/category/' + category.url">{{category.name}}</a>
          </li>
        </ul>
      </div>
      <a href="#" class="btn-blog-close-filters" @click="showFilters = false">
        <img src="/assets/icons/close-gray.png" alt="">
      </a>
    </div>
    <div class="blogs animate__animated animate__fadeInUp">
      <div class="blog" v-for="blog in currentBlogs" :key="blog.id" @click="gotoBlog(blog)">
        <div class="blog-head">
          <div class="blog-title">{{blog.title}}</div>
          <div class="blog-date">{{blog.date_published}}</div>
        </div>
        <div class="blog-body" :style="{ backgroundImage: `url(${blog.thumb_url})` }">
          <div class="hover">
            <div class="hover-title">{{blog.title}}</div>
            <div class="hover-desc">{{blog.short_desc}}</div>
            <div class="hover-link">
              <a :href="`/blog/${blog.url}`">Read full article</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <b-pagination
        v-model="page" per-page="6"
        :total-rows="filteredBlogs.length"
        align="center" limit="7">
      </b-pagination>
    </div>
    <More @next="pageMove"/>
    <BgTextree/>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import store from '@/store'
import api from '@/api'
import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    BgTextree: () => import('@/components/bg-textree'),
    BPagination
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    page: {
      type: Number,
      default: () => 1
    }
  },
  data: () => ({
    search: '',
    showFilters: false
  }),
  computed: {
    currentCategoryID: function () {
      if (this.url.length > 0) {
        for (var cat of store.state.blog.categories) {
          if (cat.url == this.url) {
            return cat.id
          }
        }
      }
      return 0;
    },
    categories: function () {
      var cats = [];
      for (var cat of store.state.blog.categories) {
        if (cat.shown) {
          cats.push(cat)
        }
      }
      return cats;
    },
    filteredBlogs: function () {
      var blogs = []
      for (var blog of store.state.blog.searchList) {
        if (this.currentCategoryID == 0 || blog.categories.includes(this.currentCategoryID)) {
          blogs.push(blog)
        }
      }
      return blogs
    },
    currentBlogs: function () {
      var last = this.page * 6
      var start = last - 6
      var end = Math.min(this.filteredBlogs.length, last)
      var c = this.filteredBlogs.slice(start, end)
      return c
    },
    pageCount: function () {
      var pcount = 1
      if (this.filteredBlogs.length > 6) {
        pcount = Math.ceil(this.filteredBlogs.length / 6)
      }
      return pcount
    }
  },
  watch: {
    pageCount(new_pcount) {
      var pages = []
      for (var ctr=0; ctr < new_pcount; ctr++) {
        pages.push(ctr)
      }
      store.commit('SET_MORE_PAGES', pages)
    }
  },
  mounted() {
    store.commit('SET_NAV_PAGE', 'blog')
    store.commit('SET_NAV_THEME', 'dark')
    store.commit('SET_MORE_THEME', 'dark')
    store.commit('SET_MORE_HAS_SCROLL', false)

    var searchQuery = this.$route.query.search || ''
    if (searchQuery.length > 0) {
      this.search = searchQuery
    }

    this.getBlogs()
    api.blog.getBlogCategories()
    api.blog.getPopularBlogs()
  },
  methods: {
    changeCategory: function (cat_id) {
      this.currentCategoryID = cat_id
      this.page = 1
      this.showFilters = false
    },
    changePage: function (p) {
      this.page = p
      store.commit('SET_MORE_CURRENT_INDEX', this.page - 1)
    },
    prevPage: function () {
      if (this.page > 1) {
        this.page--
        store.commit('SET_MORE_CURRENT_INDEX', this.page - 1)
      }
    },
    nextPage: function () {
      if (this.page < this.pageCount) {
        this.page++
        store.commit('SET_MORE_CURRENT_INDEX', this.page - 1)
      }
    },
    pageMove: function (index) {
      this.page = index + 1
    },
    getBlogs: function () {
      api.blog.getBlogs(this.search)
    },
    gotoBlog: function (blog) {
      window.location.href = `/blog/${blog.url}`
    }
  }
}
</script>
<style scoped>
div#blog-list {
  width: 100%;
  max-width: calc(1600px - 200px);
  margin: 100px auto;
}

div.blog-header-container-mobile,
div.blog-categories-mobile {
  display: none;
}

div.blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.title {
  font-family: "Montserrat";
  font-size: 30px;
  line-height: 80px;
  color: #2E2E2E;
  font-weight: 600;
}
div.blog-search input {
  border: 1px solid #707070;
  height: 42px;
  width: 360px;
  padding-left: 42px;
  padding-right: 12px;
  background: transparent url('/assets/icons/search-gray.png') no-repeat 12px center;
}

ul.blog-categories {
  list-style-type: none;
  display: flex;
  padding-left: 0;
}
ul.blog-categories li {
  margin-right: 48px;
}
ul.blog-categories li a {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: bold;
  color: #CFCFCF;
}
ul.blog-categories li.selected a {
  color: #008ED6;
}

div.blogs {
  display: flex;
  flex-wrap: wrap;
  margin: 48px 0 24px;
  justify-content: center;
}
div.blog {
  flex: 0 1 calc(33% - 36px);
  max-width: calc(33% - 36px);
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 24px;
}
div.blog-head {
  display: flex;
  align-items: center;
  margin: 6px 12px;
}
div.blog-title {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 0 70%;
}
div.blog-date {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: bold;
  color: #959595;
  flex: 0 0 30%;
  text-align: right;
}
div.blog-body {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

div.hover {
  background: rgba(0,142,214,.9);
  border-radius: 10px;
  visibility: hidden;
}
div.blog:hover div.hover {
  visibility: visible;
}
div.hover-title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  padding: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: white;
}
div.hover-desc {
  display: inline-block;
  padding: 0 24px;
  height: 85px;
  overflow-y: hidden;
  color: white;
}
div.hover-link {
  padding: 24px;
}
div.hover-link a {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  color: #FFD966;
  text-decoration: none;
}

div.bottom-content {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

@media(max-width: 1024px) {
  div#blog-list {
    padding: 0 24px;
    width: 100%;
    margin: 66px 0 24px;
  }
  ul.blog-categories {
    margin: 12px 0 24px;
  }
  ul.blog-categories li {
    margin-right: 24px;
  }
  div.title {
    font-size: 24px;
    line-height: 1;
  }
  div.blogs {
    margin: 12px 0;
  }
  div.bottom-content {
    margin-top: 0;
  }
  div.blog-head {
    display: block;
    margin: 0;
  }
  div.blog-date {
    text-align: left;
  }
  div.hover-title {
    padding: 12px;
    font-size: 16px;
  }
  div.hover-desc {
    padding: 0 12px;
    height: 50px;
  }
  div.hover-link {
    padding: 12px;
  }
}

@media(max-width: 768px) {
  div#blog-list {
    position: absolute;
    top: 66px;
    margin: 24px 0;
    bottom: 0;
    overflow-y: scroll;
  }
  div.blog-header-container {
    display: none;
  }
  div.blog-header-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div.blog-header-container-mobile div.blog-search {
    flex-grow: 1;
  }
  div.blog-header-container-mobile div.blog-search input {
    width: 100%;
  }

  a.btn-blog-open-filters img {
    width: 32px;
    height: 29px;
    margin-left: 12px;
  }

  div.blog-categories-mobile {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .77);
    z-index: 1099;
  }
  div.blog-categories-mobile div.title {
    font-size: 18px;
  }
  div.blog-categories-mobile > div {
    width: 70%;
    height: 100%;
    margin-left: auto;
    background: white;
    padding: 64px 24px 0;
  }
  div.blog-categories-mobile ul {
    margin: 24px -24px;
    padding-left: 0;
    list-style-type: none;
  }
  div.blog-categories-mobile ul li {
    padding: 0 24px;
    height: 72px;
    display: flex;
    align-items: center;
  }
  div.blog-categories-mobile ul li.selected {
    background: #008ED6;
  }
  div.blog-categories-mobile ul li.selected a {
    color: white;
  }

  div.blogs {
    margin: 24px 0 12px;
    flex-direction: column;
  }
  div.blog {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
  }
  div.blog-head {
    flex: 0 0 55%;
    max-width: 55%;
  }
  div.blog-title {
    width: 100%;
    white-space: normal;
    display: inline-block;
  }
  div.blog-body {
    border-radius: 0;
    flex: 0 0 45%;
    min-height: 95px;
  }
  div.hover {
    display: none;
  }

  a.btn-blog-close-filters {
    display: inline-block;
    margin: 12px;
    position: absolute;
    right: 0;
    top: 0;
  }
  a.btn-blog-close-filters img {
    width: 21px;
    height: 21px;
  }
}
</style>

<style>
@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}
</style>
